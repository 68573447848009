<!--
 * @Author: your name
 * @Date: 2021-10-26 11:13:13
 * @LastEditTime: 2021-10-26 16:07:03
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /official/src/components/phone/mobileFooter.vue
-->
<template>
  <div class="mobileFooterWrapper">
    <div class="topBox">
      <div class="textBox" v-for="(item, index) in footData" :key="index + 1">
        <span>{{ item.name }}</span>
      </div>
    </div>
    <div class="bottomTipBox">沪ICP备09061940号-1</div>
  </div>
</template>

<script>
export default {
  name: "MobileFooter",
  props: {
    msg: String,
  },
  data() {
    return {
      footData: [
        {
          id: 1,
          name: "公司介绍",
        },
        {
          id: 2,
          name: "解决方案",
        },
        {
          id: 3,
          name: "投资者关系",
        },
        {
          id: 4,
          name: "加入我们",
        },
      ],
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.mobileFooterWrapper {
  background: #f9f9f9;
  height: 80px;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
  .topBox {
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: #333;
    font-size: 12px;
    width: 100%;
    height: 50px;
    // background: chartreuse;
    .textBox {
    }
  }
  .bottomTipBox{
      font-size: 10px;
      color: #666;
      margin-top: 5px;
  }
}
</style>
