<template>
  <div class="footWrapper">
    <div class="topBox">
      <div class="leftBox">
        <div class="leftFirst">
          <img src="../assets/images/footer/Logo@1x.png" alt="">
          <span class="stockSpan">证券代码830799</span>
        </div>
      </div>
      <div class="centerBox">
        <div class="column">
          <div class="column-title">办公地址</div>
          <div class="column-content">
            <div>上海市浦东新区</div>
            <div>陆家嘴街道陆家嘴环路958号</div>
            <div>华能联合大厦4楼</div>
          </div>
        </div>
        <div class="column">
          <div class="column-title">联系方式</div>
          <div class="column-content">
            <div>021-68816715（上海）</div>
            <div>Public@i2finance.net</div>
          </div>
        </div>
        <div class="column">
          <div class="column-title">快速链接</div>
          <div class="column-content">
            <div @click="routerPush('/introduce')">公司介绍</div>
            <div @click="routerPush('/relations')">投资者关系</div>
            <div @click="routerPush('/joinUs')">加入我们</div>
          </div>
        </div>
      </div>
      <div class="rightBox">
        <!-- <div class="item one">
          <div class="itemTop">首页</div>
          <div class="subItem">我们的能力</div>
          <div class="subItem">市场表现</div>
          <div class="subItem">技术实力</div>
          <div class="subItem">我们的团队</div>
          <div class="subItem">全国机构</div>
        </div>
        <div class="item two">
          <div class="itemTop">公司介绍</div>
          <div class="subItem">我们的优势</div>
          <div class="subItem">艾融介绍</div>
          <div class="subItem">企业荣誉</div>
          <div class="subItem">我们的客户</div>
        </div>
        <div class="item three">
          <div class="itemTop">解决方案</div>
          <div class="subItem">整体解决方案</div>
          <div class="subItem">我们的产品</div>
          <div class="subItem">经典案例</div>
        </div>
        <div class="item four">
          <div class="itemTop">投资者关系</div>
          <div class="subItem">公告</div>
          <div class="subItem">投资者联系</div>
        </div>
        <div class="item five">
          <div class="itemTop">加入我们</div>
          <div class="subItem">走进我们</div>
          <div class="subItem">员工心声</div>
          <div class="subItem">培训成长</div>
          <div class="subItem">招聘岗位</div>
        </div> -->
        <div class="leftThird">
          <div class="qrcode1">
            <img src="../assets/images/footer/qrcode1.png" alt="">
            <div class="tiptext">艾融招聘公众号</div>
          </div>
          <div class="qrcode2">
            <img src="../assets/images/footer/qrcode2.png" alt="">
            <div class="tiptext">艾融官网</div>

          </div>
        </div>
      </div>
    </div>
    <div class="bottomBox">
      <div class="bottomContent">
        Copyright © 2016 艾融软件股份有限公司 证券代码: 830799 北京 & 上海 & 广州 & 杭州 & 珠海 & 成都 &西安 | 021-68816715(上海) | <a
          href="https://beian.miit.gov.cn/">沪ICP备09061940号-1</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  props: {
    msg: String
  },
  methods: {
    routerPush(path) {
      // this.$router.push({ path: path });
      this.$emit("footerLinkClick", {
        path: path,
      });
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.footWrapper {
  color: #D4D4D4;
  position: relative;
  width: 100%;
  height: 303px;
  background: #333;

  .topBox {
    width: 100%;
    height: 90%;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    .leftBox {

      .leftFirst {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        row-gap: 28px;

        img {
          width: 180px;
        }

        .stockSpan {
          font-size: 16px;
          color: #D4D4D4;
        }
      }

      .leftSecond {
        text-align: left;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: center;
        column-gap: 60px;
      }
    }

    .centerBox {
      text-align: left;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: center;
      column-gap: 70px;

      .column {
        display: flex;
        flex-direction: column;
        font-size: 14px;
        color: #D4D4D4;
        row-gap: 20px;

        .column-title {
          font-size: 18px;
          font-weight: bold;
          color: #FFFFFF;
        }

        .column-content {
          div:not(:last-child) {
            margin-bottom: 10px;
          }
          div{
            cursor: pointer;
          }
        }
      }

    }

    .rightBox {
      display: flex;
      justify-content: center;

      .item {
        width: 80px;
        font-size: 11px;
        text-align: left;

        .itemTop {
          font-size: 13px;
          font-weight: bold;
        }

        .subItem {
          margin-top: 10px;
        }
      }

      .leftThird {
        display: flex;
        justify-content: space-around;
        align-items: center;
        column-gap: 40px;

        .qrcode1 {
          display: flex;
          flex-direction: column;
          align-items: center;
          row-gap: 8px;

          img {
            width: 80px;
            height: 80px;
          }

          .tiptext {
            font-size: 10px;
            color: #D4D4D4;
            
          }
        }

        .qrcode2 {
          display: flex;
          flex-direction: column;
          align-items: center;
          row-gap: 8px;

          img {
            width: 80px;
            height: 80px;
          }

          .tiptext {
            font-size: 10px;
            color: #D4D4D4;
          }
        }
      }
    }
  }

  .bottomBox {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 100%;
    text-align: center;
    position: absolute;
    left: 0;
    bottom: 0;
    background: #292629;
    // background: purple;
    color: #D4D4D4;
    font-size: 12px;

    .bottomContent {
      text-align: center;
      font-size: 14px;
    }

    a {
      text-decoration: none;
      color: #D4D4D4;
    }
  }
}
</style>
