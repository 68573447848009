<template>
  <el-container direction="vertical">
    <div class="pcContainer" v-if="!isMobile">
      <el-header>
        <div class="left-menu">
          <div class="left_logo">
            <img src="../assets/images/nav/navLogo.png" alt="" />
            <span class="textSpan">股票代码:830799</span>
          </div>
          <div class="home-navmenu">
            <el-menu mode="horizontal" :default-active="activeIndex" router @select="handleSelect">
              <el-menu-item index="/first">
                <span>首页</span>
              </el-menu-item>
              <el-menu-item index="/introduce">
                <span>公司介绍</span>
              </el-menu-item>
              <el-menu-item index="/solve">
                <span>解决方案</span>
              </el-menu-item>
              <el-menu-item index="/dynamic">
                <span>公司动态</span>
              </el-menu-item>
              <el-menu-item index="/relations">
                <span>投资者关系</span>
                <!--                <span slot="title" style="font-size: 20px;position: relative;top: 1px;">投资者关系</span>-->
                <!--                <el-menu-item index="Announce">公司公告</el-menu-item>-->
                <!--                <el-menu-item index="government">公司治理</el-menu-item>-->
                <!--                <el-menu-item index="Achievement">业绩报告</el-menu-item>-->
                <!--                <el-menu-item index="Service">投资者服务</el-menu-item>-->
              </el-menu-item>
              <el-menu-item index="/joinUs">
                <span>加入我们</span>
              </el-menu-item>
            </el-menu>
          </div>
          <div class="">
            <img src="" alt="" />
          </div>
        </div>
      </el-header>
      <el-main style="padding: 0; margin-top: 1px; background-color: #eff0f4;  width: 100%;">
        <router-view></router-view>
        <Footer @footerLinkClick="footerLinkClick"></Footer>
      </el-main>
    </div>

    <div v-else class="m_wrapper">
      <div v-show="!is_after_scroll" class="top-menu" :class="{ showMenuActive: isMenuShow === true }">
        <div class="left_logo">
          <img src="../assets/images/nav/phone/navLogo.png" alt="" />
        </div>
        <div class="right_menu" @click="modelRightMenu">
          <img class="right_menuImg" src="../assets/images/nav/phone/menu.svg" alt="" />
        </div>
      </div>
      <div v-show="is_after_scroll" class="after-scrollMenu" :class="{ showMenuActive: isMenuShow === true }">
        <div class="left_logo">
          <img src="../assets/images/nav/phone/afterScroll_logo.png" alt="" />
        </div>
        <div class="right_menu" @click="modelRightMenu">
          <img class="right_menuImg" src="../assets/images/nav/phone/black_menu.png" alt="" />
        </div>
      </div>
      <el-main style="padding: 0; margin-top: 1px; background-color: #eff0f4">
        <router-view></router-view>
        <MobileFooter></MobileFooter>
      </el-main>
      <transition enter-active-class="animate__animated animate__slideInRight"
        leave-active-class="animate__animated animate__slideOutRight">
        <div v-if="isMenuShow" class="fixMenu" @touchmove.prevent>
          <div class="modelTopWraper">
            <div class="leftTop">
              <img src="../assets/images/nav/phone/modaLogo.png" alt="" />
            </div>
            <div class="crossDiv" @click="hideMenu">
              <img src="../assets/images/nav/phone/navClose.png" alt="" />
            </div>
          </div>
          <!-- <div class="middleContent">
             <div class="routeItem first" @click="routeTo('first')">
               <span>首页</span>
             </div>
             <div class="routeItem introduce" @click="routeTo('introduce')">
               <span>公司介绍</span>
             </div>
             <div class="routeItem solve" @click="routeTo('solve')">
               <span>解决方案</span>
             </div>
             <div class="routeItem relation" @click="routeTo('relation')">
               <span>投资者关系</span>
             </div>
             <div class="routeItem joinus" @click="routeTo('joinus')">
               <span>加入我们</span>
             </div>
          </div> -->
          <div class="middleContent">
            <el-menu mode="vertical" :default-active="activeIndex" router @select="handleSelect">
              <el-menu-item index="/first">
                <span>首页</span>
                <img src="../assets/images/nav/phone/navArrow.png" alt="">
              </el-menu-item>
              <el-menu-item index="/introduce">
                <span>公司介绍</span>
                <img src="../assets/images/nav/phone/navArrow.png" alt="">
              </el-menu-item>
              <el-menu-item index="/solve">
                <span>解决方案</span>
                <img src="../assets/images/nav/phone/navArrow.png" alt="">
              </el-menu-item>
              <el-menu-item index="/relations">
                <span>投资者关系</span>
                <img src="../assets/images/nav/phone/navArrow.png" alt="">
              </el-menu-item>
              <el-menu-item index="/joinUs">
                <span>加入我们</span>
                <img src="../assets/images/nav/phone/navArrow.png" alt="">
              </el-menu-item>
            </el-menu>
          </div>

          <div class="fix_bottom">
            <mobile-footer />
          </div>
        </div>
      </transition>
    </div>
  </el-container>
</template>

<script>
import { isMobile } from "../utils/index";
import Footer from "../components/footer";
import MobileFooter from "../components/phone/mobileFooter.vue";

export default {
  components: { Footer, MobileFooter },
  data() {
    return {
      activeIndex: "",
      isMobile: false,
      isMenuShow: false,
      active: '',
      block:'',
    };
  },
  created() {
    // this.activeIndex = '/first';
  },
  mounted() {
    this.activeIndex = "/first";
    this.isMobile = isMobile();
    console.log("this.isMobile", this.isMobile);
  },
  computed: {
    is_after_scroll() {
      return this.$store.state.account.afterScroll
    }
  },
  watch: {},
  methods: {
    handleSelect(index) {
      console.log('index', index);
      
      this.activeIndex = index;
      this.isMenuShow = false;
    },
    getPath() {
      this.activeIndex = this.$route.path;
    },
    modelRightMenu() {
      this.isMenuShow = true;
    },
    hideMenu() {
      this.isMenuShow = false;
    },
    // 底部-快速链接
    footerLinkClick(info) {
      this.$router.push({ path: info.path });
      this.activeIndex = info.path;
      this.isMenuShow = false;
    }
  },
};
</script>

 <style lang="less" scoped>

.pcContainer {
  width: 100%;
  position: relative;
  .el-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    overflow: hidden;
    background-color: #fff;
    // background-color: green;
    height: 80px !important;
    display: flex;
    align-items: center;
    .left-menu {
      width: 85%;
      height: 100%;
      margin: 0 auto;
      // background: purple;
      display: flex;
      .left_logo {
        width: 30%;
        display: flex;
        // background: tomato;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        img {
          width: 151px;
          height: 40px;
        }
        .textSpan {
          margin-left: 24px;
          // background: hotpink;
        }
      }
      .home-navmenu {
        width: 65%;
        height: 100%;
        padding-left: 24px;
        // background-color: peru;
        display: flex;
        align-items: center;
        .el-menu--horizontal {
          width: 100%;
          // font-size: 20px !important;
          border-bottom: none;
          .el-menu-item {
            font-size: 20px;
          }
          .el-menu-item.is-active {
            color: rgb(46, 170, 221);
            border: none;
          }
        }
        ul {
          // background: red;
          display: flex;
          li {
            list-style-type: none;
            margin: 0 25px;
            padding: 0;
          }
        }
      }
    }
  }
  .el-main {
    position: absolute;
    top: 80px;
    left:0px;
  }
}
@media screen and (max-width: 479px) {
  .m_wrapper{
    width: 100%;
    .top-menu {
    // background: red;
    width: 100vw;
    height: 60px;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 5;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &.showMenuActive {
      z-index: 2;
      background: transparent;
      opacity: 0.1;
    }
    .left_logo {
      width: 114px;
      height: 30px;
      margin-left: 20px;
    }
    .right_menu {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50px;
      height: 50px;
      margin-right: 20px;
      // background: blue;
      // float: right;
      .right_menuImg {
        width: 20px;
        height: 16px;
        // background: chocolate;
      }
    }
  }
  .after-scrollMenu{
    width: 100vw;
    height: 60px;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 5;
    display: flex;
    background:#FFF;
    box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.2);
    justify-content: space-between;
    align-items: center;

     .left_logo {
      width: 114px;
      height: 30px;
      margin-left: 20px;
      img{
        width: 115px;
        height: 30px;
      }
    }
    .right_menu {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50px;
      height: 50px;
      margin-right: 20px;
      // background: blue;
      // float: right;
      .right_menuImg {
        width: 20px;
        height: 16px;
        // background: chocolate;
      }
    }
  }
  .fixMenu {
    // display: ;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background:rgba(255, 255, 255, 88%);
    // background: green;
    z-index: 7;
    opacity: 1;
    .modelTopWraper {
      width: 100%;
      height: 60px;
      // background: tomato;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .leftTop {
        margin-left: 20px;
        img {
          width: 115px;
          height: 30px;
        }
      }
      .crossDiv {
        width: 40px;
        height: 40px;
        display: inline-block;
        display: flex;
        justify-content: center;
        align-items: center;
        // background: blueviolet;
        margin-right: 20px;
        img {
          width: 18px;
          height: 18px;
        }
      }
    }

    .middleContent {
      // background: cadetblue;
      margin-top: 60px;
      .el-menu {
        .el-menu-item {
          border-bottom: 1px solid #E0E0E0;
          padding: 0 15px !important;
          display: flex;
          justify-content: space-between;
          align-items: center;
          img{
            width: 6px;
            height: 8px;
          }
        }
        .routeItem {
        font-size: 14px;
        color: #333;
        height: 60px;
      
        span {
          margin-left: 15px;
        }
      }
      }
      
    }
    .fix_bottom {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
    }
  }

  }
  
}
</style>


